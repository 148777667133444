// Set the target date to year 3000
const targetDate = new Date("January 1, 3000 00:00:00").getTime();

// Update the countdown every second
const countdownTimer = setInterval(() => {
  // Get the current date and time
  const currentDate = new Date().getTime();

  // Calculate the remaining time
  const remainingTime = targetDate - currentDate;

  // Calculate the remaining years, months, days, hours, minutes, and seconds
  const years = Math.floor(remainingTime / (1000 * 60 * 60 * 24 * 365));
  const remainingMonths = 11 - new Date().getMonth();
  const remainingDays = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() - new Date().getDate();
  const remainingHours = 23 - new Date().getHours();
  const remainingMinutes = 59 - new Date().getMinutes();
  const remainingSeconds = 59 - new Date().getSeconds();

  // Display the remaining time in the HTML elements with the respective ids
  document.getElementById("seconds").innerHTML = `
   <div>${remainingSeconds}</div>
  `;

  document.getElementById("countdown").innerHTML = `
    <div><div>${remainingMinutes} <div class="title">Minutes</div></div></div> 
    <div><div>${remainingHours} <div class="title">Hours</div></div></div> 
    <div><div>${remainingDays} <div class="title">days</div></div></div> 
    <div><div>${remainingMonths} <div class="title">months</div></div></div> 
    <div><div>${years} <div class="title">years</div></div></div> 
  `;

  // Add minutes and hours to the countdown
  document.getElementById("minutes").innerHTML = `
    <div>${remainingMinutes} <div class="title">minutes</div></div>
  `;
  
  document.getElementById("hours").innerHTML = `
    <div>${remainingHours} <div class="title">hours</div></div>
  `;

  // Check if the countdown has reached zero
  if (remainingTime < 0) {
    clearInterval(countdownTimer);
    document.getElementById("countdown1").innerHTML = "<p>Countdown finished!</p>";
    document.getElementById("countdown2").innerHTML = "<p>Countdown finished!</p>";
  }
}, 1000);
